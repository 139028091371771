import Vue from "vue";
import store from "@/store";

export default {
  getValuesLength(source) {
    let arr = source;
    if (!Array.isArray(source)) {
      arr = source.split(/[\n,，、]/);
    }

    let set = new Set(arr);
    let length = 0;

    set.forEach((v) => {
      if (v && v.trim()) {
        length++;
      }
    });

    return length;
  },
  getAddressDesc(country, province, city, zipCode) {
    let list = [];

    if (country) {
      list.push(country);
    }

    if (province) {
      list.push(province);
    }

    if (city) {
      list.push(city);
    }

    if (zipCode) {
      list.push(zipCode);
    }

    return list.join(",");
  },
  copyText(text) {
    let inputDom = document.createElement("textarea");
    inputDom.setAttribute("readonly", "readonly");
    inputDom.value = text;
    document.body.appendChild(inputDom);
    inputDom.select();
    document.execCommand("Copy");
    inputDom.style.display = "none";
    inputDom.remove();
  },

  getValues(source, filter) {
    let arr = source;
    if (!Array.isArray(source)) {
      arr = source.split(/[\n,，、]/);
    }

    let set = new Set(arr);
    let arrTemp = [];

    set.forEach((v) => {
      let item = v && v.trim();
      if (item) {
        arrTemp.push({
          value: item,
          length: item.replace(/[^\x00-\xff]/g, "01").length,
        });
      }
    });

    if (filter) {
      arrTemp = arrTemp.filter((v) => filter(v));
    }

    arrTemp = arrTemp.sort((a, b) => {
      return a.length - b.length;
    });

    return arrTemp.map((v) => v.value);
  },
  getRandomItem(source) {
    if (source.length == 0) {
      return null;
    }

    let index = Math.floor(Math.random() * source.length);
    return source[index];
  },

  getRandomList(source, count, minCount) {
    if (source.length == 0) {
      return [];
    }

    let arr = source.slice(0);
    let result = [];
    let index = 0;
    while (arr.length > 0 && result.length < count) {
      index = Math.floor(Math.random() * arr.length);
      result.push(arr[index]);
      arr.splice(index, 1);
    }

    while (result.length < minCount) {
      index = Math.floor(Math.random() * source.length);
      result.push(source[index]);
    }

    return result;
  },

  createComponent(component, props, target) {
    const vm = new Vue({
      store,
      // createElement 返回虚拟dom
      render(createElement) {
        // 将 component 作为根组件渲染出来
        // createElement(标签名称或组件配置对象，传递属性、事件等，孩子元素)
        return createElement(component, { props });
      },
    }).$mount(); // 挂载是为了把虚拟dom变成真实dom

    target.appendChild(vm.$el);

    // 实例
    const comp = vm.$children[0];

    // 淘汰机制
    comp.remove = () => {
      // 删除dom
      target.removeChild(vm.$el);
      // 销毁组件
      vm.$destroy();
    };

    // 返回Component组件实例
    return comp;
  },

  setToken(token, flag) {
    if (flag == 1) {
      sessionStorage.setItem("X-Client-Token", token);
    } else if (flag == 2) {
      localStorage.setItem("X-Client-Token", token);
    } else if (flag == 0) {
      if (sessionStorage.getItem("X-Client-Token")) {
        sessionStorage.setItem("X-Client-Token", token);
      } else {
        localStorage.setItem("X-Client-Token", token);
      }
    }
  },

  getToken() {
    let sessionStorageToken = sessionStorage.getItem("X-Client-Token");
    if (sessionStorageToken) {
      return sessionStorageToken;
    } else {
      return localStorage.getItem("X-Client-Token");
    }
  },

  removeToken() {
    localStorage.removeItem("X-Client-Token");
    sessionStorage.removeItem("X-Client-Token");
  },

  isAbsolutePath(source) {
    return /^(https?:|mailto:|tel:)/.test(source);
  },

  getFullPath(currentPath, newPath) {
    if (this.isAbsolutePath(newPath)) {
      return newPath;
    } else {
      let separator = "";
      if (currentPath && newPath) {
        separator = "/";
      }

      return currentPath + separator + newPath;
    }
  },
};
