<template>
  <div class="header" :style="{ top: this.$store.state.hasTranslate ? '40px' : '' }">
    <div class="title">
      <div class="title-wrapper">
        <span class="title-left">清仓易<i>-</i>专业尾货库存交易信息平台</span>
        <div class="title-right">
          <div id="translateGoogle"> </div>
          <img v-if="image" class="country-flag" :style="{ 'margin-right': marginRight + 'px' }" :src="image" alt="" />
          <el-select v-model="country" popper-class="select-popper" class="select-country" @change="changeCountryFlag(true)" style="margin-right: 13px">
            <el-option v-for="item in countryList" :key="item.value" :label="item.text" :value="item.value">
              <img class="select-country-flag" v-if="item.value" :src="item.Url" alt="" width="20px" height="10px" />
              <span> {{ item.text }}</span>
            </el-option>
          </el-select>
          <span> &nbsp;&nbsp;{{ loginUserId ? loginUserId : "" }}&nbsp;&nbsp;</span>
          <span v-if="loginUserId" style="display: inline-block; width: 1px; height: 14px; background: #cccccc"></span>
          <a v-if="!loginUserId" @click="login()"> &nbsp;&nbsp;登录 &nbsp; </a> <i v-if="!loginUserId" style="font-size: 13px; color: #cccccc">|</i> <a v-if="!loginUserId" @click="login()"> &nbsp;<span> 免费注册</span></a>
          <a v-else @click="exit"> &nbsp;&nbsp;退出</a>
        </div>
      </div>
    </div>
    <div class="search">
      <div class="search-wrapper">
        <div class="search-left">
          <a href="/" style="margin: auto">
            <img style="width: 90px; height: 61px; margin: auto" src="@/assets/images/logo.png" alt="" />
          </a>
          <div style="margin: auto 50px; display: flex">
            <el-form @submit.native.prevent>
              <div class="search-content">
                <div class="search-select">
                  <el-select v-model="category" class="el-select" @change="changeCategoryFlag(true)">
                    <el-option v-for="item in categoryList" :key="item.value" :label="item.text" :value="item.value"></el-option>
                  </el-select>
                </div>
                <div class="category" :style="{ left: left + 'px' }" style="font-weight: 400">
                  {{ categoryDesc }}
                </div>
                <div class="search-input">
                  <el-input placeholder="你在找什么？" v-model="keyword" class="input-with-select" style="font-size: 16px"> </el-input>
                </div>
              </div>
              <div>
                <el-button native-type="submit" class="serach-button" slot="append" @click="onSubmit()"> <i class="iconfont icon-a-sousuo1" style="font-size: 18px; margin-right: 10px"></i>搜索</el-button>
              </div>
            </el-form>
          </div>
        </div>
        <div class="search-right">
          <el-button type="primary" @click="publishStock">发布库存</el-button>
          <el-button style="color: #009dff" @click="customerService">联系我们</el-button>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 14px; background: #f5f7fa"></div>
  </div>
</template>
<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import LoginDialog from "@/views/Component/LoginDialog.vue";
import CustomerService from "@/views/index/CustomerService";
export default {
  components: {},
  props: ["useCategory", "getList", "login"],
  data() {
    return {
      keyword: "",
      category: "",
      categoryDesc: "全部分类",
      country: "",
      image: "",
      countryList: [
        { text: "全部国家", value: "" },
        ...this.$store.state.countryList.map((v) => {
          return { text: v.Name, value: v.Id, Url: v.Url };
        }),
      ],
      categoryList: [
        { text: "全部分类", value: "" },
        ...this.$store.state.categoryList.map((v) => {
          return { text: v.Name, value: v.Id };
        }),
      ],
    };
  },
  computed: {
    loginUserId() {
      return this.$store.state.loginUserId;
    },
    left() {
      if (this.category.length > 4) {
        return 135;
      } else {
        return 145;
      }
    },
    isProductDetail() {
      if (this.$route.path.startsWith("/productDetail")) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    exit() {
      Util.removeToken();
      this.$store.commit("setLoginUserId", "");
      if (!this.isProductDetail) {
        this.getList(true, this.country, this.category, this.keyword);
      }
    },
    onSubmit() {
      if (this.isProductDetail || this.$route.query.serach || this.$route.query.category || this.$route.query.country) {
        let params = [];
        if (this.keyword) {
          params.push(`serach=${this.keyword}`);
        }
        if (this.category) {
          params.push(`category=${this.category}`);
        }
        if (this.country) {
          params.push(`country=${this.country}`);
        }
        let path = params.length > 0 ? "/?" + params.join("&") : "/";
        this.$router.replace(path);
      }
      this.getList?.(true, this.country, this.category, this.keyword);
    },

    async publishStock() {
      if (!this.loginUserId) {
        this.login();
      } else {
        this.$router.push("/productList/editProduct");
      }
    },
    customerService() {
      var dialog = Util.createComponent(CustomerService, {}, document.querySelector("#app"));
      dialog.open();
    },

    changeCategoryFlag(isRefresh) {
      if (this.category) {
        let target = this.categoryList.find((v) => v.value == this.category);
        this.categoryDesc = target.text;
      } else {
        this.categoryDesc = "全部分类";
      }
      if (isRefresh) {
        this.onSubmit();
      }
    },
    changeCountryFlag(isRefresh) {
      if (this.country) {
        let target = this.countryList.find((v) => v.value == this.country);
        if (target.text.length < 3) {
          this.marginRight = -40;
        } else {
          this.marginRight = -25;
        }
        this.image = target.Url;
        this.countryDesc = target.text;
      } else {
        this.image = false;
      }
      if (isRefresh) {
        this.onSubmit();
      }
    },
  },
  watch: {},
  mounted() {
    let serach = this.$route.query?.serach;
    let category = this.$route.query?.category;
    let country = this.$route.query?.country;

    this.keyword = serach ? serach : "";
    this.category = category ? +category : "";
    this.country = country ? +country : "";

    if (serach || category || country) {
      this.changeCountryFlag(false);
      this.changeCategoryFlag(false);
      this.getList(true, country, category, serach);
    }
  },
};
</script>

<style lang="scss">
.select-popper {
  .el-scrollbar {
    .el-select-dropdown__wrap {
      max-height: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.header {
  top: 0;
  width: 100%;
  position: fixed;
  transform: translate(-50%);
  left: 50%;
  z-index: 10;

  .title {
    background: #f5f7fa;

    .title-wrapper {
      width: 1200px;
      height: 30px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title-left {
      font-size: 13px;
      color: #999999;
    }

    .title-right {
      font-size: 14px;
      color: #2f2f2f;
      display: flex;
      align-items: center;

      a {
        color: inherit;
      }
    }
  }

  .search {
    background: #ffffff;

    .search-wrapper {
      width: 1200px;
      margin: 0 auto;
      height: 76px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .search-left {
      display: flex;
      flex-direction: row;
      position: relative;

      ::v-deep {
        .el-form {
          display: flex;
          flex-direction: row;

          .input-with-select {
            width: 300px;

            .el-input__inner {
              padding-left: 5px !important;
            }
          }
        }
      }

      .search-content {
        display: flex;
        flex-direction: row;
        border: 2px solid #009dff;
        border-radius: 2px 0 0 2px;
        border-right: 0;

        .search-select {
          ::v-deep {
            .el-select {
              height: 100%;

              .el-input {
                height: 100%;
              }
            }
          }
        }
      }

      .el-select {
        width: 125px;
        border-right: 0;

        ::v-deep {
          .el-option {
            max-height: 500px;
          }

          .el-input {
            z-index: 10;
          }

          .el-input__inner {
            visibility: hidden;
          }

          .el-input__suffix {
            border-right: 1.5px solid #ccc;

            span {
              .is-reverse {
                transform: rotateZ(180deg);
              }

              i {
                font-size: 20px;
                margin-right: 15px;
                margin-top: 2px;
                transform: rotateZ(0);
              }

              i::before {
                content: "\e790";
              }
            }
          }
        }
      }

      .category {
        position: absolute;
        top: 30px;
        left: 135px;
        width: 60px;
        margin-left: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .serach-button {
        width: 120px;
        height: 40px;
        color: white;
        text-align: center;
        border: 0;
        border-radius: 0 2px 2px 0;
        background-color: #009dff;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
      }

      .el-input {
        ::v-deep {
          .el-input__inner {
            height: 35px;
            color: #2f2f2f;
            border: 0;
          }

          .el-input-group__prepend {
            width: 70px;
            border: 0;
          }

          .el-input-group__append {
            border: 0;
          }

          .el-option {
            left: 100px;
          }
        }
      }
    }

    .search-right {
      line-height: 76px;

      button {
        font-size: 16px;
      }

      .el-button--primary {
        width: 120px;
        height: 40px;
        background: #009dff;
        border-radius: 2px;
      }

      .el-button--default {
        width: 120px;
        height: 40px;
        border: 1px solid #009dff;
        border-radius: 2px;
      }
    }
  }
}

.select-country {
  width: 90px;

  ::v-deep {
    .el-input__inner {
      border: 0 !important;
      text-align: end;
      background-color: transparent;
      padding-right: 20px;
    }

    .el-input__suffix {
      right: 0;

      span {
        .is-reverse {
          transform: rotateZ(180deg);
        }

        i {
          transform: rotateZ(0);
        }

        i::before {
          content: "\e790";
        }
      }

      .el-icon-arrow-up {
        width: 16px;
      }
    }
  }
}

.country-flag {
  width: 25px;
  margin-right: -20px;
  border: 1px solid #ccc;
}

.select-country-flag {
  border: 1px solid #ccc;
}
</style>
