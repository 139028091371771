<template>
  <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="800px" :close-on-click-modal="false">
    <div class="login">
      <div class="close" @click="close"> <i class="el-icon-close"></i> </div>
      <div class="login-box">
        <div class="login-left">
          <div class="login-left-box">
            <h2
              >清仓易--全站点海外库存清仓服务在线交易网站。<br /><br />
              服务中国跨境卖家和全球海外华人，提供跨境电商海外库存一站式交易服务。<br /><br />
              我们承诺：</h2
            >
            <div class="describe"><i class="el-icon-check"></i> 全站点库存清仓，童叟无欺</div>
            <div class="describe"><i class="el-icon-check"></i> 搜索、社交、群组等多渠道爆光</div>
            <div class="describe"><i class="el-icon-check"></i> 免费注册，不成交0费用</div>
            <div class="describe"><i class="el-icon-check"></i> 价格透明，无差价</div>
            <div class="describe"><i class="el-icon-check"></i> 交易真实可靠，钱&货安全有保障</div>
            <div class="describe">......</div>
          </div>
        </div>
        <div class="login-right">
          <div class="login-right-title">
            <h1>微信一键登录</h1>
          </div>
          <div class="login-right-content">
            <div class="step"> <div class="step-content">1</div> &nbsp;<span>打开微信扫码 —— &nbsp;</span> <div class="step-content">2</div> &nbsp;<span>关注微信公众号 —— &nbsp;</span> <div class="step-content">3</div> &nbsp;<span>登录注册成功</span> </div>

            <div v-loading="Status == 2 ? true : false" element-loading-spinner="el-icon" element-loading-background="rgba(255, 255, 255, 0.9)" class="image">
              <div v-if="Status == 2" class="overdue">
                二维码已过期<br />
                <br />
                <a @click="reset"> 刷新重试</a>
              </div>
              <el-image v-loading="loading" style="width: 230px; height: 230px" :src="Url ?? ''">
                <div slot="error"> </div>
              </el-image>
            </div>
          </div>
          <div class="login-right-foot">
            <div style="margin-left: 20px"> 登录表示同意 <a href="/agreement" target="_blank" style="text-decoration: underline"> 《用户协议》</a></div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";

export default {
  name: "LoginDialog",
  components: {},
  props: ["refresh"],
  data() {
    return {
      Url: "",
      Ticket: "",
      dialogVisible: false,
      loading: "true",
      Status: "",
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getQrCode();
    },

    async getQrCode() {
      let result = await Service.getQrCode();
      this.Url = result.Url;
      this.Ticket = result.Ticket;
      this.loading = false;
      this.getQrCodeStatus();
    },

    close() {
      this.dialogVisible = false;
    },

    reset() {
      this.Status = "";
      this.getQrCode();
    },

    async getQrCodeStatus() {
      let result = await Service.getQrCodeStatus({
        ticket: this.Ticket,
      });
      this.Status = result.Status;
      if (result.Status == 0) {
        if (this.dialogVisible) {
          await this.getQrCodeStatus();
        }
      } else if (result.Status == 1) {
        this.$message.success("登录成功");
        if (Util.getToken()) {
          let result = await Service.getHome();
          this.$store.commit("setLoginUserId", result.Id);
        }
        this.dialogVisible = false;
        this.refresh(true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  position: relative;

  .close {
    color: #999;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 24px;
    line-height: 31px;
    position: absolute;
    right: -40px;
    cursor: pointer;
    text-align: center;
  }

  .login-box {
    display: flex;
    flex-direction: row;

    .login-left {
      width: 300px;
      height: 530px;
      background-image: url("../../assets/images/bk.png");
    }

    .login-left-box {
      width: 250px;
      margin: 0 auto;

      h2 {
        margin-top: 0;
        padding-top: 50px;
        text-align: left;
        color: #3f82c9;
      }

      .describe {
        text-align: left;
        color: #3f82c9;
        line-height: 28px;
      }
    }
  }

  .login-right {
    background: #fff;
    width: 500px;
    position: relative;
    height: 530px;
    border-radius: 20px;

    .login-right-title {
      text-align: center;

      h1 {
        margin-top: 30px;
        margin-bottom: 35px;
      }
    }

    .login-right-content {
      height: 386px;

      .step {
        display: flex;
        align-items: center;
        color: #666;
        font-size: 13px;
        text-align: center;
        justify-content: center;

        span {
          line-height: 18px;
        }

        .step-content {
          line-height: 22px;
          width: 21px;
          height: 22px;
          border-radius: 20px;
          background-color: #077cf4;
          color: #fff;
        }
      }

      .image {
        text-align: center;
        margin-top: 68px;
      }
    }

    .login-right-foot {
      display: flex;
      align-items: center;
      height: 45px;
      width: 100%;
      background-color: #f5f9fe;
      border-bottom-right-radius: 20px;
    }
  }
}

.image {
  position: relative;

  .overdue {
    position: absolute;
    font-size: 18px;
    top: 40%;
    left: 39%;
    z-index: 2000000;
    color: black;

    a {
      color: red;
    }
  }
}

::v-deep {
  .el-dialog {
    box-sizing: border-box;
    border-radius: 20px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    position: relative;
    border-top: 0;
    padding: 0;
  }
}
</style>
